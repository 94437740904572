import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { User } from "@/store/modules/AuthModule";
import HTTPService from "@/core/services/HTTPService";
import AuthService from "@/core/services/AuthService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/tomorrow",
        name: "tomorrow",
        component: () => import("@/views/Tomorrow/ListPage.vue"),
      },
      {
        path: "/tomorrow/add",
        name: "tomorrow-add",
        component: () => import("@/views/Tomorrow/AddPage.vue"),
      },
      {
        path: "/histories/weight",
        name: "history-weight",
        component: () => import("@/views/History/WeightPage.vue"),
      },
      {
        path: "/histories/weight/create",
        name: "history-weight-create",
        component: () => import("@/views/History/WeightCreatePage.vue"),
      },
      {
        path: "/finance/",
        name: "finance",
        component: () => import("@/views/Finance/ListPage.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/t/:code",
    name: "t",
    meta: { auth: false },
    component: () => import("@/views/Tomorrow/LandingPage.vue"),
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        meta: { auth: false },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        meta: { auth: false },

        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        meta: { auth: false },

        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (r) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const needAuth =
    typeof r.meta.auth !== "undefined" ? !(r.meta.auth === false) : true;

  if (needAuth) {
    //const user = store.getters.currentUser;
    if (AuthService.getToken().length === 0) {
      router.push({ name: "sign-in" }).then(() => {
        console.warn("not auth");
      });
    }

    /*
    if (typeof user.id === "undefined") {
      router.push({ name: "sign-in" }).then(() => {
        console.warn("not auth");
      });
    }*/
    /*
    await store
      .dispatch(Actions.VERIFY_AUTH, {
        api_token: JwtService.getToken(),
      })
      .then(() => {

        if (typeof store.getters.getErrors.loginPage !== "undefined") {
          router.push({ name: "sign-in" });
        }
      });*/
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
